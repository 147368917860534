import React from 'react'
import { FaPhoneAlt } from 'react-icons/fa'

const CallIcon = () => {
    return (
      <a
        href="tel:01273465336"
        className="floating-call-old floating-icon d-md-none cta-icon"
        id="Call Icon Single"
      >
        <FaPhoneAlt style={{ marginTop: "18px", fontSize: "1.5rem" }} />
      </a>
    );
}

export default CallIcon
