import React from "react";
import { Link } from "gatsby";

const SlLink = ({ children, ...rest }) => {
  return (
    <Link className="sl-link" {...rest}>
      {children}
    </Link>
  );
};

export default SlLink;
