import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {
	AiFillFacebook,
	AiFillTwitterSquare,
	AiFillLinkedin,
} from "react-icons/ai";

const Footer = ({ tel }) => {
	const telNum = tel || "1273 465 336";
	const data = useStaticQuery(graphql`
		{
			allFile(filter: { absolutePath: { regex: "/images/payment-logos/" } }) {
				edges {
					node {
						id
						childImageSharp {
							gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
						}
					}
				}
			}
		}
	`);
	return (
		<Container fluid={true} className="bg-black text-offwhite pt-10">
			<Container>
				<Row>
					<Col xs={12} sm={6} lg={4}>
						<Row>
							<Col
								xs={12}
								className="d-flex justify-content-center justify-content-sm-start"
							>
								<Link to="/">
									<StaticImage
										src="../images/logo.png"
										style={{ width: "160px" }}
										className="mb-4"
										placeholder="tracedSVG"
										alt="Seymour Locksmiths Logo"
									/>
								</Link>
							</Col>
							<Col
								xs={12}
								className="d-flex justify-content-center justify-content-sm-start"
							>
								<a
									className="d-block text-offwhite text-md mb-3 hover-no-decoration"
									href="tel:+447710 408 414"
								>
									07710 408 414
								</a>
							</Col>
							<Col
								xs={12}
								className="d-flex justify-content-center justify-content-sm-start"
							>
								<a
									className="d-block text-offwhite text-md mb-3 hover-no-decoration"
									href={`tel:+44${telNum}`}
								>
									0{telNum}
								</a>
							</Col>
							<Col
								xs={12}
								className="d-flex justify-content-center justify-content-sm-start"
							>
								<a
									className="d-block text-offwhite text-md mb-3 hover-no-decoration"
									href="mailto:info@seymour-locksmiths.co.uk"
								>
									info@seymour-locksmiths.co.uk
								</a>
							</Col>
							<Col
								xs={12}
								className="d-flex justify-content-center justify-content-sm-start"
							>
								<a
									href="https://g.page/seymour-locksmiths?share"
									target="_blank"
									rel="noreferrer"
									className="text-center text-offwhite text-sm-start mb-3 hover-no-decoration"
								>
									Jeffrey Seymour t/a Seymour Locksmiths <br />
									Basepoint Business Centre
									<br />
									Little High Street
									<br />
									Shoreham-by-Sea <br />
									BN43 5EG
								</a>
							</Col>
						</Row>
					</Col>
					<Col xs={12} sm={6} lg={4}>
						<Row>
							<Col
								xs={12}
								className="d-flex justify-content-center justify-content-sm-end justify-content-lg-center"
							>
								<h4 className="text-2xl my-4">Social</h4>
							</Col>
							<Col
								xs={12}
								className="d-flex justify-content-center justify-content-sm-end justify-content-lg-center"
							>
								<Row>
									<Col className="d-flex">
										<a
											className="social-icon"
											target="_blank"
											rel="noreferrer"
											href="https://www.facebook.com/SeymourLocksmiths"
										>
											<AiFillFacebook className="social-icons text-offwhite" />
										</a>

										<a
											className="social-icon"
											target="_blank"
											rel="noreferrer"
											href="https://twitter.com/jefflocksmith"
										>
											<AiFillTwitterSquare className="social-icons text-offwhite" />
										</a>

										<a
											className="social-icon"
											target="_blank"
											rel="noreferrer"
											href="https://www.linkedin.com/in/jeffrey-seymour-258344b7/"
										>
											<AiFillLinkedin className="social-icons text-offwhite" />
										</a>
									</Col>
								</Row>
							</Col>
						</Row>
						<Row className="text-center text-sm-end text-lg-center mt-5">
							<Col>
								<h4>Payment methods accepted</h4>
							</Col>
						</Row>
						<Row>
							{data.allFile.edges.map((logo) => (
								<Col xs={3} key={logo.node.id} className="mt-1 p-1">
									<GatsbyImage
										image={logo.node.childImageSharp.gatsbyImageData}
										alt="Logo"
									/>
								</Col>
							))}
						</Row>
					</Col>
					<Col xs={12} md={6} lg={2}>
						<Row>
							<Col
								xs={12}
								className="d-flex justify-content-lg-center justify-content-center justify-content-md-start "
							>
								<h4 className="text-2xl mb-4 mt-4">Links</h4>
							</Col>
							<Col
								xs={12}
								className="d-flex justify-content-lg-center justify-content-center justify-content-md-start"
							>
								<Link
									className="d-block text-offwhite text-md mb-3 hover-no-decoration"
									to="/what-we-do"
								>
									What we do
								</Link>
							</Col>
							<Col
								xs={12}
								className="d-flex justify-content-lg-center justify-content-center justify-content-md-start"
							>
								<Link
									className="d-block text-offwhite text-md mb-3 hover-no-decoration"
									to="/who-we-are"
								>
									Who we are
								</Link>
							</Col>
							<Col
								xs={12}
								className="d-flex justify-content-lg-center justify-content-center justify-content-md-start"
							>
								<Link
									className="d-block text-offwhite text-md mb-3 hover-no-decoration"
									to="/resources"
								>
									Resources
								</Link>
							</Col>
							<Col
								xs={12}
								className="d-flex justify-content-lg-center justify-content-center justify-content-md-start"
							>
								<Link
									className="d-block text-offwhite text-md mb-3 hover-no-decoration"
									to="/our-locksmith-reviews"
								>
									Reviews
								</Link>
							</Col>
							<Col
								xs={12}
								className="d-flex justify-content-lg-center justify-content-center justify-content-md-start"
							>
								<Link
									className="d-block text-offwhite text-md mb-3 hover-no-decoration"
									to="/contact-us"
								>
									Contact
								</Link>
							</Col>
						</Row>
					</Col>
					<Col xs={12} md={6} lg={2}>
						<Row>
							<Col
								xs={12}
								className="d-flex justify-content-center justify-content-sm-start justify-content-md-end"
							>
								<h4 className="text-2xl mb-4 mt-4">Areas</h4>
							</Col>
							<Col
								xs={12}
								className="d-flex justify-content-center justify-content-sm-start justify-content-md-end"
							>
								<Link
									className="d-block text-offwhite text-md mb-3 hover-no-decoration"
									to="/locksmith-in-hove"
								>
									Hove
								</Link>
							</Col>
							<Col
								xs={12}
								className="d-flex justify-content-center justify-content-sm-start justify-content-md-end"
							>
								<Link
									className="d-block text-offwhite text-md mb-3 hover-no-decoration"
									to="/locksmith-in-brighton"
								>
									Brighton
								</Link>
							</Col>
							<Col
								xs={12}
								className="d-flex justify-content-center justify-content-sm-start justify-content-md-end"
							>
								<Link
									className="d-block text-offwhite text-md mb-3 hover-no-decoration"
									to="/locksmith-in-lancing"
								>
									Lancing
								</Link>
							</Col>
							<Col
								xs={12}
								className="d-flex justify-content-center justify-content-sm-start justify-content-md-end"
							>
								<Link
									className="d-block text-offwhite text-md mb-3 hover-no-decoration"
									to="/locksmith-in-portslade"
								>
									Portslade
								</Link>
							</Col>
							<Col
								xs={12}
								className="d-flex justify-content-center justify-content-sm-start justify-content-md-end"
							>
								<Link
									className="d-block text-offwhite text-md mb-3 hover-no-decoration"
									to="/locksmith-in-shoreham"
								>
									Shoreham-by-Sea
								</Link>
							</Col>
							<Col
								xs={12}
								className="d-flex justify-content-center justify-content-sm-start justify-content-md-end"
							>
								<Link
									className="d-block text-offwhite text-md mb-3 hover-no-decoration"
									to="/locksmith-in-southwick"
								>
									Southwick
								</Link>
							</Col>
							<Col
								xs={12}
								className="d-flex justify-content-center justify-content-sm-start justify-content-md-end"
							>
								<Link
									className="d-block text-offwhite text-md mb-3 hover-no-decoration"
									to="/locksmith-in-steyning"
								>
									Steyning
								</Link>
							</Col>
							<Col
								xs={12}
								className="d-flex justify-content-center justify-content-sm-start justify-content-md-end"
							>
								<Link
									className="d-block text-offwhite text-md mb-3 hover-no-decoration"
									to="/locksmith-in-worthing"
								>
									Worthing
								</Link>
							</Col>
							<Col
								xs={12}
								className="d-flex justify-content-center justify-content-sm-start justify-content-md-end"
							>
								<Link
									className="d-block text-offwhite text-md mb-3 hover-no-decoration"
									to="/locksmith-in-seaford"
								>
									Seaford
								</Link>
							</Col>
							<Col
								xs={12}
								className="d-flex justify-content-center justify-content-sm-start justify-content-md-end"
							>
								<Link
									className="d-block text-offwhite text-md mb-3 hover-no-decoration"
									to="/locksmith-in-newhaven"
								>
									Newhaven
								</Link>
							</Col>
							<Col
								xs={12}
								className="d-flex justify-content-center justify-content-sm-start justify-content-md-end"
							>
								<Link
									className="d-block text-offwhite text-md mb-3 hover-no-decoration"
									to="/locksmith-in-henfield"
								>
									Henfield
								</Link>
							</Col>
							<Col
								xs={12}
								className="d-flex justify-content-center justify-content-sm-start justify-content-md-end"
							>
								<Link
									className="d-block text-offwhite text-md mb-3 hover-no-decoration"
									to="/locksmith-in-lewes"
								>
									Lewes
								</Link>
							</Col>
							<Col
								xs={12}
								className="d-flex justify-content-center justify-content-sm-start justify-content-md-end"
							>
								<Link
									className="d-block text-offwhite text-md mb-3 hover-no-decoration"
									to="/locksmith-in-kemptown"
								>
									Kemptown
								</Link>
							</Col>
							<Col
								xs={12}
								className="d-flex justify-content-center justify-content-sm-start justify-content-md-end"
							>
								<Link
									className="d-block text-offwhite text-md mb-3 hover-no-decoration"
									to="/locksmith-in-saltdean"
								>
									Saltdean
								</Link>
							</Col>
							<Col
								xs={12}
								className="d-flex justify-content-center justify-content-sm-start justify-content-md-end"
							>
								<Link
									className="d-block text-offwhite text-md mb-3 hover-no-decoration"
									to="/locksmith-in-peacehaven"
								>
									Peacehaven
								</Link>
							</Col>
						</Row>
					</Col>
				</Row>

				<Row className="text-center mt-5">
					<Col>
						<a
							className="d-block text-offwhite text-md mb-3 hover-no-decoration"
							href="https://www.rjm.digital/locksmith-web-design"
						>
							Website designed by RJM Digital
						</a>{" "}
					</Col>
				</Row>
			</Container>
		</Container>
	);
};

export default Footer;
