import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SlButton from "./button";
import GrayBanner from "./grayBanner";
import { Link } from "gatsby";

const CallNowBanner = ({ heading, button, button2 }) => {
  return (
    <GrayBanner>
      <Row className="justify-content-center">
        <Col xs={11} sm={10}>
          <Row className="align-items-center">
            <Col xs={12} className="mb-xl-5">
              <h3 className="callbanner-text text-xl-center">{heading}</h3>
            </Col>
            {button && button.url && (
              <Col
                xs={12}
                lg={button2 && button2.url ? 6 : 12}
                className={`d-flex justify-content-center ${
                  button2 && button2.url
                    ? "justify-content-xl-end"
                    : "justify-content-xl-center"
                }`}
              >
                <SlButton
                  className="text-lg fw-700"
                  variant="primary"
                  as="a"
                  href={button?.url}
                >
                  {button?.title}
                </SlButton>
              </Col>
            )}

            {button2 && button2.url && (
              <Col
                xs={12}
                lg={6}
                className="d-none d-lg-flex justify-content-center justify-content-xl-start"
              >
                <SlButton
                  className="text-lg fw-700"
                  variant="outline-primary"
                  as={Link}
                  to={button2.url}
                >
                  {button2?.title}
                </SlButton>
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    </GrayBanner>
  );
};

export default CallNowBanner;
