import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "@fontsource/montserrat/400.css";
import "@fontsource/montserrat/500.css";
import "@fontsource/montserrat/500-italic.css";
import "@fontsource/montserrat/600.css";
import "@fontsource/montserrat/700.css";
import "@fontsource/lato/400.css";
import "@fontsource/lato/700.css";
import "../styles/index.css";
import Navigation from "./navigation";
import Footer from "./footer";
import { Helmet } from "react-helmet";
import Container from "react-bootstrap/Container";
import { CookieBanner } from "@connorrjm/rjm-cookie-banner";

const Layout = ({ children, tel }) => {
	if (typeof Window !== "undefined") {
		var w =
			window.innerWidth ||
			document.documentElement.clientWidth ||
			document.body.clientWidth;
	}

	return (
		<div>
			<Helmet>
				{typeof Window !== "undefined" && (
					<script
						src="https://formspree.io/js/formbutton-v1.min.js"
						defer
					></script>
				)}
				{typeof Window !== "undefined" && w > 767 && (
					<script
						type="text/javascript"
						innerHTML={`  
       window.formbutton=window.formbutton||function(){(formbutton.q=formbutton.q||[]).push(arguments)};
    formbutton("create", {
      action: "https://formspree.io/f/mqkwykeo",
       title: "Request a call back",
       fields: [
          { 
        type: "text", 
        label: "Name:", 
        name: "name",
        required: true,
        placeholder: "Jeff Seymour"
      },
         { 
        type: "text", 
        label: "Tel:", 
        name: "telephone",
        required: true,
        placeholder: "01273465336"
      },
     
      { 
        type: "email", 
        label: "Email:", 
        name: "_replyto",
        required: false,
        placeholder: "your@email.com"
      },
      {
        type: "textarea",
        label: "Message:",
        name: "message",
        placeholder: "How can we help you?",
      },
      { type: "submit" }      
    ],
    styles: {
      title: {
        backgroundColor: "black"
      },
      button: {
        backgroundColor: "black",
      }
    }
   });
    `}
					/>
				)}
			</Helmet>
			<Container fluid></Container>
			<Navigation tel={tel} />
			<CookieBanner disableUrl />
			{children}
			<Footer tel={tel} />
		</div>
	);
};

export default Layout;
