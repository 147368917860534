import React from "react";
import Container from "react-bootstrap/Container";

const GrayBanner = ({ children }) => {
  return (
    <Container fluid={true} className="py-24 bg-lgray">
      {children}
    </Container>
  );
};

export default GrayBanner;
